import React from 'react';
import './css/App.css';
import Nav from './Nav';
import CT from './CT';
import gif from './images/dNFT.gif';
import bLogo from './images/logo_flat_tb.png';
import Footer from './Footer';

function Home() {
  return (
    <div className="home">
      <Nav />
      <main className="main">
        <section className="hero-container">
          <div className="hero-container-child">
            <div className="hcc-ls">
              <h1>PoM</h1>
              <h3>A UNIQUE PROOF OF MEMBERSHIP IN ANY <span id="changing-text"><CT /></span></h3>
              <a href="https://discord.gg/McqF7vyCWx" target="_blank">Let's talk</a>
            </div>
            <div className="hcc-rs">
              <img src={gif} alt="A gif highlighting Proof of membership NFTs"/>
            </div>
          </div>
        </section>
        <section className="wip-container">
          <h2>WHAT IS PoM?</h2>
          <div className="wip-ci">
            <div className="wip-ci-t">
              <h6>PoM</h6>
              <h5>A Protocol</h5>
            </div>
            <div className="wip-ci-i">
              <p>PoM is a <b>decentralized token-based membership system</b> for web3 communities. Groups, projects and DAOs can register their unique community, and enable minting of personalized & customizable membership SoulBound Tokens (e.g. john@randomdao) with graphical representation.</p>
            </div>
          </div>
        </section>
        <section className="wp-container">
          <h2>WHY PoM?</h2>
          <div className="wp-ci">
            <div className="wp-ci-b">
              <p>Generate multiple revenue streams to develop your community, DAO or project</p>
            </div>
            <div className="wp-ci-b">
              <p>Foster community involvement and a sense of belonging among all your members</p>
            </div>
            <div className="wp-ci-b">
              <p>Enable joint decision-making by membership SoulBound Token holders</p>
            </div>
            <div className="wp-ci-b">
              <p>Offer SBT-based community incentives, exclusive perks and privileges</p>
            </div>
            <div className="wp-ci-b">
              <p>No need in an own NFT's development, and simplicity at its core</p>
            </div>
          </div>
        </section>
        <section className="hdiw-container">
          <h2>HOW DOES IT WORK?</h2>
          <div className="hdiw-ci">
            <p>With PoM, collectives can effortlessly register their community to make issuance of unique membership SoulBound Tokens available for members, and increase community cohesion, members engagement, and available resources.</p>
            <p> There are just 3 steps:<br></br>
                1. <b>Register your community name</b> onchain via PoM web app<br></br>
                2. <b>Set up memberships parameters</b> onchain<br></br>
                3. <b>Invite your community members to mint membership SBTs</b>
            </p>
            <p>Members can mint their personalized & customizable membership SoulBound Tokens (SBTs), featuring a name of their choice, to showcase their membership status, make contribution and level up experience with the community.</p>
          </div>
        </section>
        <section className="bcm-container">
          <h2>BENEFITS TO COLLECTIVE's MEMBERS</h2>
          <div className="bcm-ci">
            <div className="bcm-ci-ls">
              <h5>PoM as a membership SBT</h5>
              <p>PoM is a personalized and customizable digital membership card in the form of a dynamic SoulBound Token (SBT), featuring the member's chosen name.</p>
            </div>
            <div className="bcm-ci-rs">
              <div className="bcm-ci-rs-h">
                <p>Benefits for members minting SBT</p>
              </div>
              <div className="bcm-ci-rs-b">
                <p>- obtain a unique, personalized and customizable SBT as tangible proof of your membership status</p>
                <p>- showcase your involvement and valuable support</p>
                <p>- gain access to exclusive benefits, perks and privileges</p>
              </div>
            </div>
          </div>
        </section>
        <section className="cta-container">
          <div className="cta-c1">
            <div className="cta-c1-q">
              <span></span>
              <p>Want to offer something engaging to your members?</p>
            </div>
            <div className="cta-c1-q">
              <span></span>
              <p>Lacking technical or design resources?</p>
            </div>
            <div className="cta-c1-q">
              <span></span>
              <p>Don't have a dedicated web page for your community, DAO or project?</p>
            </div>
          </div>
          <div className="cta-c2">
            <p>We've got you covered!</p>
          </div>
          <div className="cta-c3">
            <a href="https://forms.gle/qAsZ74NM5GZjpRc88" target="_blank">Get notified about the PoM launch</a>
          </div>
        </section>
        <section className="ab-container">
          <img src={bLogo} alt="Beast DAO logo"/>
          <h5>PoM is developed by BEAST DAO members.</h5>
          <div className="ab-ci">
            <p>BEAST DAO is a members-owned project, revolutionizing how we collaborate.<br></br>It's your portal to co-create, fund and govern killer products.</p>
            <p>BEAST mission is to provide a way people can come and work together on projects they care about, having access to needed resources, expertise and connections.</p>
            <p>To fulfill our mission, we are forming an ever-growing community of technical and non-technical builders set to create tooling for Web3.</p>
          </div>
          <div className="ab-b">
            <a href="https://beastdao.org/#gi" target="_blank">Get involved with BEAST</a>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
