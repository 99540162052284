import './css/App.css';
import logo from './images/logo_card_tb.png';

function Nav() {
  return (
    <header className="top">
      <div className="nav">
        <div className="lp">
          <img src={logo} alt="Proof of membership service logo"/>
        </div>
        <nav className="np">
          <ul className="npp">
            <li className="npc"><a href="#" target="_blank" id="he">About</a></li>
          </ul>
        </nav>
        <div className="rp">
          <a href="https://docs.google.com/document/d/1RX-2SWga6wW8FaI2_-peGLF7hd5jnRpbaIsavd5r4tY/edit?usp=sharing" target="_blank" id="he">FAQ</a>
          <a href="https://app.pom.cards/" target="_blank" id="button">Try App</a>
        </div>
      </div>
    </header>
  );
}

export default Nav;
