import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import Home from './Home';
import Nav from './Nav';
import Footer from './Footer';

function App() {
  return (
    <Routes>
      <Route exact path='/' element={<Home/>}/>
      <Route exact path='/Nav' element={<Nav/>}/>
      <Route exact path='/Footer' element={<Footer/>}/>
    </Routes>
  );
}

export default App;
