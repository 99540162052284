import React, { useState, useEffect } from 'react';

function CT() {
  const texts = ['COLLECTIVE', 'DAO', 'COMMUNITY', 'PROJECT'];
  const [currentText, setCurrentText] = useState(texts[0]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * texts.length);
      setCurrentText(texts[randomIndex]);
    }, 1500);

    return () => {
      clearInterval(intervalId);
    };
  }, [texts]);

  return <h4>{currentText}</h4>;
}

export default CT;
