import './css/App.css';
import dLogo from './images/discord.png';
import tLogo from './images/twitter.png';

function Footer() {
  return (
    <footer className="bottom">
      <div className="nav" id="navf">
        <div className="cp">
          <p>© 2023 BEAST DAO</p>
        </div>
        <nav className="np" id="npf">
          <ul className="npp" id="nppf">
            <li className="npi"><a href="https://drive.google.com/file/d/1nQ0Zrc218dltS4_VLLozjO6Scq_EofOB/view?usp=sharing" target="_blank">Terms of Service</a></li>
            <li className="npi"><a href="https://drive.google.com/file/d/1KLN47IRUQ5LQ8ctzg6WKxaBu3rO8R4YX/view?usp=sharing" target="_blank">Privacy</a></li>
            <li className="npi"><a href="mailto:pomcardsnft@gmail.com">Contact us</a></li>
          </ul>
        </nav>
        <div className="rp" id="rpf">
          <div className="rpi">
            <a href="https://discord.gg/McqF7vyCWx" target="_blank"><img src={dLogo} alt="Discord logo" /></a>
            <a href="https://twitter.com/pomcards" target="_blank"><img src={tLogo} alt="Twitter logo" /></a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
